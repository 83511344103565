import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Cookies from 'universal-cookie';
// import { GoogleSpreadsheet } from "google-spreadsheet";
// import g from "../g-sheets.json";
// import Shop from './ShopMainPage';
// const soldOutIcon = "https://i.imgur.com/YOFn3yw.png";
// const cartIcon =  'https://i.imgur.com/iGOZvHv.png';
// const cookies = new Cookies();
// let loaded = false;
function Copyright(props) {

    // const cookies = new Cookies();
    // const orderNum = cookies.get('orderNum');
    // console.log(orderNum)
    // getOrder(parseInt(orderNum));
    setTimeout(saveCookies, 350);

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'- Loading - '}
            {' '}

            {' '}
        </Typography>
    );
}
// async function getOrder(orderNum) {
//     const SPREADSHEET_ID = process.env.REACT_APP_NEW_SHEET_ID;
//     const SHEET_ID = process.env.REACT_APP_INVENTORY;
//     const CLIENT_EMAIL = process.env.REACT_APP_NEW_CLIENT_EMAIL;
//     const PRIVATE_KEY = g.private_key;
//     const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
//     cookies.remove('allShopData', { path: '/' });
//     try {
//         await doc.useServiceAccountAuth({
//             client_email: CLIENT_EMAIL,
//             private_key: PRIVATE_KEY,
//         });
//         await doc.loadInfo();
//         const sheet = doc.sheetsById[SHEET_ID];
//         await sheet.loadCells("A1:T2500");
//         let allValues = await sheet.getRows();
//         let allShopData = [];
//         //(row (starts at 1), data listed below)
//             //0 id
//             //1 = sequoia-s
//             //2 = Mech Boro
//             //3 = price
//             //4 = image
//             //5 = category
//             //6 = inventory (x for unlimited)
//             //7 = stripeId
//             //8 = not real yet, but eventually a more info page
//         for (var i = 1; i < allValues.length; i++) {
//             let tempInv = sheet.getCell(i, 6).value;
//             if (!isNaN(parseInt(tempInv))){
//                 if (parseInt(sheet.getCell(i, 6).value) > 0 ) {
//                     allShopData.push({
//                         img: sheet.getCell(i, 4).value,
//                         name: sheet.getCell(i, 1).value,
//                         description: sheet.getCell(i, 2).value,
//                         id: sheet.getCell(i, 0).value,
//                         price: sheet.getCell(i, 3).value,
//                         category: sheet.getCell(i, 5).value,
//                         inventory: sheet.getCell(i, 6).value,
//                         stripeId: sheet.getCell(i, 7).value,
//                         iconId: cartIcon,
//                         soldOut: false,
//                         variant: 'success'
//                     })
//                 } else {
//                     allShopData.push({
//                         img: sheet.getCell(i, 4).value,
//                         name: sheet.getCell(i, 1).value,
//                         description: sheet.getCell(i, 2).value,
//                         id: sheet.getCell(i, 0).value,
//                         price: sheet.getCell(i, 3).value,
//                         category: sheet.getCell(i, 5).value,
//                         inventory: sheet.getCell(i, 6).value,
//                         stripeId: sheet.getCell(i, 7).value,
//                         iconId: soldOutIcon,
//                         soldOut: true,
//                         variant: 'error'
//                     })
//                 }
//             } else if (sheet.getCell(i, 6).value.toLowerCase() === 'x') {
//                 allShopData.push({
//                     img: sheet.getCell(i, 4).value,
//                     name: sheet.getCell(i, 1).value,
//                     description: sheet.getCell(i, 2).value,
//                     id: sheet.getCell(i, 0).value,
//                     price: sheet.getCell(i, 3).value,
//                     category: sheet.getCell(i, 5).value,
//                     inventory: sheet.getCell(i, 6).value,
//                     stripeId: sheet.getCell(i, 7).value,
//                     iconId: cartIcon,
//                     soldOut: false,
//                     variant: 'success'
//                 })
//             }
      
//         }
//         // loaded = true;
//         saveCookies(allShopData);
//     } catch (e) {
//         console.error("Error: ", e);
//     }
// }
function saveCookies() {
    let nowURL = window.location.href;
    nowURL = nowURL.split('#/');
    let buildURL = nowURL[0] + "#/shopinfo";
    const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
    // if (orderInfoList[0].id !== '') {

    //     cookies.set('allShopData', orderInfoList, { path: '/' });
    //     console.log('here')
    //     let nowURL = window.location.href;
    //     nowURL = nowURL.split('#/');
    //     let buildURL = nowURL[0] + "#/shopinfo";
    //     const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
    //     if (newWindow) newWindow.opener = null
    // } else {
    //     let nowURL = window.location.href;
    //     nowURL = nowURL.split('#/');
    //     let buildURL = nowURL[0] + "#/error";
    //     const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
    //     if (newWindow) newWindow.opener = null
    // }
}
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function LoadShop() {

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
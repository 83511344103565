import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
// import CircularProgress from '@mui/material/CircularProgress';
import { GoogleSpreadsheet } from "google-spreadsheet";
import g from "../g-sheets.json";
let urlForStripe = ""
let tempCunt = ['AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CV', 'CW', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MK', 'ML', 'MM', 'MN', 'MO', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SZ', 'TA', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW', 'ZZ'];
const cookies = new Cookies();
const steps = ['Address', 'Cart'];
let realFinalCart = [];
let realFinalAddress = [];
let buildOrder = {
    email: '',
    name: '',
    address: '',
    body: '',
    panel: '',
    button: '',
    url: ''
};
let shipUSAStripeInfo = {
    img: '',
    name: 'USA SHIPPING',
    description: '',
    id: '30',
    price: '9',
    category: 'ship',
    inventory: 'x',
    stripeId: 'price_1KXsdWDBekB952FtS05BAcIq',
    quanitity: 1
}
let shipINTERNStripeInfo = {
    img: '',
    name: 'NON-USA SHIPPING',
    description: '',
    id: '31',
    price: '40',
    category: 'ship',
    inventory: 'x',
    stripeId: 'price_1KXscpDBekB952FtFtiPSHDD',
    quanitity: 1
}
function getStepContent(step) {
    switch (step) {
        case 1:
            return <Cart />;
        case 0:
            return <AddressForm />;
        default:
            throw new Error('Unknown step');
    }
}
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.cherrypine.net/#/">
                CherryPine Mods
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function CartPage({ cartInfo }) {
    realFinalCart = cartInfo;
    // const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = React.useState(0);
    // const [scroll, setScroll] = React.useState('paper');
    // const handleNext = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     // cookies.remove('orderNum', { path: '/' });
    //     // cookies.remove('orderInfoList', { path: '/' });
    //     // history.push('/loading');
    //     realFinalAddress = ({
    //         email: data.get('email'),
    //         fullName: data.get('firstName'),
    //         address1: data.get('address1'),
    //         address2: data.get('address2'),
    //         city: data.get('city'),
    //         state: data.get('state'),
    //         zip: data.get('zip'),
    //         country: data.get('country'),
    //     });
    //     console.log(realFinalAddress);
    //     setActiveStep(activeStep + 1);

    //     setActiveStep(activeStep + 1);
    // };
    const handlePay = (e) => {
        e.preventDefault();
        const newWindow = window.open(urlForStripe);
        if (newWindow) newWindow.opener = null
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // cookies.remove('orderNum', { path: '/' });
        // cookies.remove('orderInfoList', { path: '/' });
        // history.push('/loading');
        if (data.get('country') !== null) {
            realFinalAddress = ({
                email: data.get('email'),
                fullName: data.get('firstName'),
                address1: data.get('address1'),
                address2: data.get('address2'),
                city: data.get('city'),
                state: data.get('state'),
                zip: data.get('zip'),
                country: data.get('country'),
            });


            let temp = realFinalAddress.country.toLowerCase();

            if (temp === 'us' || temp === 'usa' || temp === 'united states' || temp === 'united states of america' || temp === 'us of a' || temp === 'america' || temp === 'states' || temp === 'united america' || temp === 'north america') {
                for (var o = 0; o < realFinalCart.length; o++) {
                    if (realFinalCart[o].id === "30" || realFinalCart[o].id === "31") {
                        realFinalCart.splice(o, 1);
                    }
                }

                realFinalCart.push(shipUSAStripeInfo)
            } else {
                for (var oo = 0; oo < realFinalCart.length; oo++) {
                    if (realFinalCart[oo].id === "30" || realFinalCart[oo].id === "31") {
                        realFinalCart.splice(oo, 1);
                    }
                }
                realFinalCart.push(shipINTERNStripeInfo)
            }
        }
        if (data.get('country') === null) {
            let temp2 = realFinalAddress.country.toLowerCase();
            if (temp2 === 'us' || temp2 === 'usa' || temp2 === 'united states' || temp2 === 'united states of america' || temp2 === 'us of a' || temp2 === 'america' || temp2 === 'states' || temp2 === 'united america' || temp2 === 'north america') {
                createOrder(false)
            } else createOrder(true);
        }

        console.log(data.get('country'))
        console.log(activeStep)

        let inputtedInfo = false;

        if (data.get('email') !== "" && data.get('firstName') !== "" && data.get('address1') !== "" && data.get('city') !== "" && data.get('zip') !== "" && data.get('country') !== "") {
            inputtedInfo = true;
        }

        if (!inputtedInfo && activeStep === 0) {
            alert('Missing Fields on Address')

        } else if (realFinalCart.length <= 1 && activeStep === 1) {
            alert('No Items In Cart')
        } else setActiveStep(activeStep + 1);

       


    };
    function createOrder(internOrder) {
        let lineItems = ''
        // let lineLocation = 0;
        let tempLineCunts = '';
        let combineInfo = [];
        for (var t = 0; t < realFinalCart.length; t++) {
            let alreadyAdded = false;
            for (var s = 0; s < combineInfo.length; s++) {
                if (combineInfo[s].stripeId === realFinalCart[t].stripeId) {
                    alreadyAdded = true;
                    combineInfo[s].quanitity += realFinalCart[t].quanitity;
                }
            }
            if (!alreadyAdded) {
                combineInfo.push({
                    stripeId: realFinalCart[t].stripeId,
                    quanitity: realFinalCart[t].quanitity
                })
            }
        }
        for (var x = 0; x < combineInfo.length; x++) {
            lineItems += `line_items[${x}][price]=${combineInfo[x].stripeId}&line_items[${x}][quantity]=${combineInfo[x].quanitity}&`
        }
        if (!internOrder) {
            lineItems += `allow_promotion_codes=true&shipping_address_collection[allowed_countries][0]=US`
        } else {
            for (var i = 0; i < tempCunt.length; i++) {
                tempLineCunts += `shipping_address_collection[allowed_countries][${i}]=${tempCunt[i]}&`
            }
            lineItems += `allow_promotion_codes=true&${tempLineCunts}`
        }
        let buildBodyInfo = '';
        let buildPanelInfo = '';
        let buildButtonInfo = '';
        let buildAddress = realFinalAddress.address1 + ', ' + realFinalAddress.address2 + ', ' + realFinalAddress.city + ', ' + realFinalAddress.state + ', ' + realFinalAddress.zip + ', ' + realFinalAddress.country;
        for (var p = 0; p < realFinalCart.length; p++) {
            if (realFinalCart[p].id !== "30" && realFinalCart[p].id !== "31") {
                for (var g = 0; g < parseInt(realFinalCart[p].quanitity); g++) {
                    let splitcolorName = realFinalCart[p].description.split("-")
                    buildBodyInfo += realFinalCart[p].category + ' Body - ' + splitcolorName[splitcolorName.length - 1] + " + ";
                    if (realFinalCart[p].category === "Sapling") {
                        buildPanelInfo += "N/A Saplings don't have panels + "
                    } else buildPanelInfo += realFinalCart[p].category + ' Panels - ' + splitcolorName[splitcolorName.length - 1] + " + ";

                    buildButtonInfo += realFinalCart[p].category + ' Button - ' + splitcolorName[splitcolorName.length - 1] + " + ";
                }

            }
        }
        buildBodyInfo = buildBodyInfo.slice(0, -2);
        buildPanelInfo = "N/A Maples don't have panels";
        buildButtonInfo = buildButtonInfo.slice(0, -2);
            if(realFinalCart[1].img === "cerakoted") {
                buildBodyInfo = realFinalCart[0].name;
                
                buildButtonInfo = realFinalCart[0].name;
            }else {

                buildBodyInfo = realFinalCart[0].name;
            }
        buildOrder = {
            email: realFinalAddress.email,
            fullName: realFinalAddress.fullName,
            address: buildAddress,
            body: buildBodyInfo,
            panel: buildPanelInfo,
            button: buildButtonInfo,
            url: lineItems
        }
        if (realFinalCart[0].quanitity > 1){
            buildOrder.fullName = realFinalCart[0].quanitity + "x MODS - " + buildOrder.fullName; 
        }
        if (realFinalCart[1].inventory === "silver"){
            buildOrder.fullName += " - SILVER CONTACTS"; 
        }
        if (realFinalCart[1].img === "cerakoted") {
            buildOrder.fullName += " - Cerakoted " + realFinalCart[1].name;
        }else if(realFinalCart[1].img === "cerakoted2") {
            buildOrder.fullName += " - LIMITED EDITION Cerakoted " + realFinalCart[1].name;
        }

        if(realFinalCart.length > 1){
            saveOrder();
        }
        
        // setActiveStep(activeStep + 1);

    }
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="absolute" >
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >

                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            CherryPine Mods
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <CssBaseline />
                                        <AppBar
                                            position="absolute"
                                            color="default"
                                            elevation={0}
                                            sx={{
                                                position: 'relative',
                                                borderBottom: (t) => `1px solid ${t.palette.divider}`,
                                            }}
                                        >

                                        </AppBar>
                                        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                                            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                                                <Typography component="h1" variant="h4" align="center">
                                                    Checkout
                                                </Typography>
                                                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                                                    {steps.map((label) => (
                                                        <Step key={label}>
                                                            <StepLabel>{label}</StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                                {activeStep === steps.length ? (
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom>
                                                            (Payment Screen Is Loading... It will open in a new tab)
                                                        </Typography>
                                                        <Typography variant="subtitle1">
                                                            Once your payment is confirmed, we will reach out to you (via DM's) with your order number.
                                                            You can use that to track your order status. This is a manual process, so please be patient! This screen is not a confirmation of payment.

                                                            I can't thank you all enough for your continued support. Much love - Mikey

                                                            If the payment screen didn't open automatically, <button onClick={handlePay}>Click Here</button>
                                                        </Typography>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                                            {getStepContent(activeStep)}
                                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {activeStep !== 0 && (
                                                                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                                                        Back
                                                                    </Button>
                                                                )}

                                                                <Button
                                                                    variant="contained"
                                                                    type="submit"
                                                                    sx={{ mt: 3, ml: 1 }}
                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Place Order' : 'Next'}
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                )}
                                            </Paper>
                                            <Copyright />
                                        </Container>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>

        </ThemeProvider>
    );
}

let cartNow = cookies.get('cartItems');
//plus https://i.imgur.com/Ceny0Af.png
//minus https://i.imgur.com/rSOICQI.png
//delete https://i.imgur.com/svfs037.png

// const products = [
//     {
//         name: 'Product 1',
//         desc: 'A nice thing',
//         price: '$9.99',
//     },
//     {
//         name: 'Product 2',
//         desc: 'Another thing',
//         price: '$3.45',
//     },
//     {
//         name: 'Product 3',
//         desc: 'Something else',
//         price: '$6.51',
//     },
//     {
//         name: 'Product 4',
//         desc: 'Best thing of all',
//         price: '$14.11',
//     },
//     { name: 'Shipping', desc: '', price: 'Free' },
// ];

// let products = cartNow;
if (cartNow === undefined) {
    console.log('here', cartNow)
    // products = [
    //     {
    //         name: 'No Products',
    //         desc: 'Add something to your cart!',
    //         price: '0',
    //     }
    // ];
} else console.log(cartNow)
// const addresses = ['1 MUI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
const payments = [
    { name: 'Payment Will Be Collected On the Next Screen Via Stripe', detail: '' },
    { name: '', detail: '' },
    { name: '', detail: '' },
    { name: '', detail: '' },
];

function Cart() {
    let totalPrice = 0;
    for (var i = 0; i < realFinalCart.length; i++) {
        totalPrice += (parseInt(realFinalCart[i].price) * realFinalCart[i].quanitity)
    }
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Order summary
            </Typography>
            <List disablePadding >
                {realFinalCart.map((product) => (
                    <ListItem key={product.id} sx={{ py: 1, px: 0 }}>
                        <ListItemText primary={product.name + " (" + product.quanitity + "x)"} secondary={product.description} />
                        <Typography variant="body2">${product.price * product.quanitity}.00</Typography>
                    </ListItem>
                ))}

                <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        ${totalPrice}.00
                    </Typography>
                </ListItem>
            </List>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Shipping
                    </Typography>
                    <Typography gutterBottom>{realFinalAddress.fullName}</Typography>
                    <Typography gutterBottom>{realFinalAddress.address1}, {realFinalAddress.address2}, {realFinalAddress.city}, {realFinalAddress.state}, {realFinalAddress.zip}, {realFinalAddress.country}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Payment details
                    </Typography>
                    <Grid container>
                        {payments.map((payment) => (
                            <React.Fragment key={payment.name}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{payment.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>{payment.detail}</Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

function AddressForm() {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Shipping address
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required="true"
                        id="firstName"
                        name="firstName"
                        label="FB Name"
                        fullWidth
                        autoComplete="name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required="true"
                        id="email"
                        name="email"
                        label="Email Address"
                        fullWidth
                        autoComplete="email"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required="true"
                        id="address1"
                        name="address1"
                        label="Address line 1"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="address2"
                        name="address2"
                        label="Address line 2"
                        fullWidth
                        autoComplete="shipping address-line2"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required="true"
                        id="city"
                        name="city"
                        label="City"
                        fullWidth
                        autoComplete="shipping address-level2"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="state"
                        name="state"
                        label="State/Province/Region"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required="true"
                        id="zip"
                        name="zip"
                        label="Zip / Postal code"
                        fullWidth
                        autoComplete="shipping postal-code"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required="true"
                        id="country"
                        name="country"
                        label="Country"
                        fullWidth
                        autoComplete="shipping country"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
async function openUrl() {
    // const CLIENT_EMAIL = process.env.REACT_APP_NEW_CLIENT_EMAIL;
    const CLIENT_STRIPE = process.env.REACT_APP_STRIPE_SK;
    console.log('HERE HERE HERE')
    console.log(buildOrder.url)

    fetch("https://api.stripe.com/v1/payment_links", {
        body: buildOrder.url,
        headers: {
            Authorization: "Bearer " + CLIENT_STRIPE,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
    }).then(response => response.json())
        .then(data => {
            let url = data.url;
            urlForStripe = url;
            const newWindow = window.open(url);
            if (newWindow) newWindow.opener = null
        });
}
async function saveOrder() {
    const SPREADSHEET_ID = process.env.REACT_APP_NEW_SHEET_ID;
    const SHEET_ID = process.env.REACT_APP_OPEN_DROP;
    const CLIENT_EMAIL = process.env.REACT_APP_NEW_CLIENT_EMAIL;
    const PRIVATE_KEY = g.private_key;
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
        await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL,
            private_key: PRIVATE_KEY,
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID];

        // await sheet2.loadCells("A1:O2004");
        await sheet.loadCells("A1:O20000");


        // var ss = SpreadsheetApp.getActiveSpreadsheet().getRange('NotShipped!A2:A2000');

        let allCyberValues = await sheet.getRows();
        let endOfCyber = allCyberValues.length;

        sheet.getCell(endOfCyber + 1, 0).value = buildOrder.email;
        sheet.getCell(endOfCyber + 1, 1).value = buildOrder.fullName;
        sheet.getCell(endOfCyber + 1, 2).value = buildOrder.address
        sheet.getCell(endOfCyber + 1, 3).value = buildOrder.body
        sheet.getCell(endOfCyber + 1, 4).value = buildOrder.panel
        sheet.getCell(endOfCyber + 1, 5).value = buildOrder.button

        await sheet.saveUpdatedCells();

        openUrl();
    } catch (e) {
        alert("Error: ", e);
    }
}
// TODO remove, this demo shouldn't need to reset the theme.


// function LoadingTune() {

//     return (
//         <ThemeProvider theme={defaultTheme}>
//             <Container component="main" maxWidth="xs">
//                 <CssBaseline />
//                 <Box
//                     sx={{
//                         marginTop: 8,
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                     }}
//                 >
//                     <CircularProgress />
//                 </Box>
//                 <Copyright sx={{ mt: 8, mb: 4 }} />
//             </Container>
//         </ThemeProvider>
//     );
// }

// function Copyright2(props) {

//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'- Complete Payment On Stripe - '}
//             {' - This page will not change after you pay - '}

//             {' - If you paid you are all set. We will PM you a tracking number for the website soon, this is a manual process, please be patient - '}
//         </Typography>
//     );
// }

// function StripeOpened() {

//     return (
//         <ThemeProvider theme={defaultTheme}>
//             <Container component="main" maxWidth="xs">
//                 <CssBaseline />
//                 <Box
//                     sx={{
//                         marginTop: 8,
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                     }}
//                 >
//                     <PaymentsIcon />
//                 </Box>
//                 <Copyright2 sx={{ mt: 8, mb: 4 }} />
//             </Container>
//         </ThemeProvider>
//     );
// }
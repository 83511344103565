import React from "react";
import "./Styles/App.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import MaingPage from "./Components/MaingPage";
import withTracker from "./withTracker";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import FixedBottomNavigation from './Components/BottomNav';
import Track from "./Components/Track";
// import Order from "./Components/OrderInfo";
import Loading from "./Components/Loading";
import  Error  from "./Components/Error";
// import ShopMain from "./Components/ShopMain"
import Tune from "./Components/TuneUp";
// import Shop from "./Components/AllShop/ShopMainPage";
import IntegrationNotistack from "./Components/AllShop/NotiStack";
import LoadShop from "./Components/AllShop/LoadshopData";
// import CartPage from "./Components/AllShop/CartPage";
import CartNoti from "./Components/AllShop/CartNoti";
import LoadingOrder from "./Components/GetOrderRoute";
import Dashboard from "./Components/Admin/Dashboard";
import MapleBuild from "./Components/AllShop/MapleProductBuilder";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Terms from "./Components/Terms";
import ComingSoon from "./ComingSoon";
import Fullfilment from "./Components/Fullfilment";
var history = createBrowserHistory();
ReactGA.initialize("G-6GE0FRBKNY", {
  siteSpeedSampleRate: 100,
});
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location) => {
  window.ga("set", "page", location.hash + location.search);
  window.ga("send", "pageview");
});
class FantasyBot extends React.Component {


  render() {
    return (
      <>
         <Router>
          <Switch>
          <Route
              exact
              path="/"
              component={withTracker(MaingPage)}
            />
            <Route
              exact
              path="/admin"
              component={withTracker(Dashboard)}
            />
            <Route
              exact
              path="/privacy"
              component={withTracker(PrivacyPolicy)}
            />
            <Route
              exact
              path="/terms"
              component={withTracker(Terms)}
            />
            <Route
              exact
              path="/fulfilment"
              component={withTracker(Fullfilment)}
            />
            <Route
              exact
              path="/maple123"
              component={withTracker(MapleBuild)}
            />
            <Route
              exact
              path="/track"
              component={withTracker(Track)}
            />
            <Route
              exact
              path="/shop"
              component={withTracker(LoadShop)}
            />
            <Route
              exact
              path="/shopinfo"
              component={withTracker(ComingSoon)}
            />
            <Route
              exact
              path="/shopinfo123"
              component={withTracker(IntegrationNotistack)}
            />
            <Route
              exact
              path="/cart"
              component={withTracker(CartNoti)}
            />
            <Route
              path="/order/"
              component={withTracker(LoadingOrder)}
            />
            <Route
              exact
              path="/error"
              component={withTracker(Error)}
            />
            <Route
              exact
              path="/loading"
              component={withTracker(Loading)}
            />
            <Route
              exact
              path="/tune"
              component={withTracker(Tune)}
            />
          </Switch>
          </Router>
        <FixedBottomNavigation className="mui-fixed" sx={{position: 'fixed', bottom: '0', overflowY: 'hidden'}}/>

      </>
    );
  }
}
export default FantasyBot;

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import { GoogleSpreadsheet } from "google-spreadsheet";
import g from "./g-sheets.json";

const cookies = new Cookies();

function Copyright(props) {

    // const cookies = new Cookies();
    const orderTune = cookies.get('orderTune');
    console.log(orderTune)
    saveOrder(orderTune);
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'- A Stripe Payment Page Will Open (This page will not update after payment, if you paid on the checkout page, then you are all good to go!) - '}
            {' '}

            {' '}
        </Typography>
    );
}
async function saveOrder(orderData) {
    const SPREADSHEET_ID = process.env.REACT_APP_NEW_SHEET_ID;
    const SHEET_ID = process.env.REACT_APP_TUNE_UP;
    const CLIENT_EMAIL = process.env.REACT_APP_NEW_CLIENT_EMAIL;
    const PRIVATE_KEY = g.private_key;
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
    let temp = orderData.country.toLowerCase();
    let stripeUrl = '';
  
    if (temp === 'us' || temp === 'usa' || temp === 'united states' || temp === 'united states of america' || temp === 'us of a' || temp === 'america' || temp === 'states' || temp === 'united america' || temp === 'north america') {
        stripeUrl =  "https://buy.stripe.com/4gw15ycgidjX2SQ3ci";
    } else stripeUrl = "https://buy.stripe.com/5kA15yeoq4Nr3WU14b";

    try {
        await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL,
            private_key: PRIVATE_KEY,
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID];

        // await sheet2.loadCells("A1:O2004");
        await sheet.loadCells("A1:O20000");


        // var ss = SpreadsheetApp.getActiveSpreadsheet().getRange('NotShipped!A2:A2000');

        let allCyberValues = await sheet.getRows();
        let endOfCyber = allCyberValues.length;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        sheet.getCell(endOfCyber + 1, 0).value = today;
        sheet.getCell(endOfCyber + 1, 1).value = orderData.email;
        sheet.getCell(endOfCyber + 1, 2).value = orderData.fullName;
        sheet.getCell(endOfCyber + 1, 3).value = orderData.address1 + ', ' + orderData.address2 + ', ' + orderData.city + ', ' + orderData.state + ', ' + orderData.zip + ', ' + orderData.country ;
        await sheet.saveUpdatedCells();

        window.open(stripeUrl);
    } catch (e) {
        console.error("Error: ", e);
    }
}
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoadingTune() {

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './AddressForm';
import StripeOpened from './StripeOpened';
import LoadingTune from './LoadTunePay';
import Cookies from 'universal-cookie';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
const cookies = new Cookies();
const steps = ['Shipping address', 'Saving Data', 'Pay On Stripe'];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <AddressForm />;
        case 1:
            return <LoadingTune />;
        case 2:
            return <StripeOpened />;
        default:
            throw new Error('Unknown step');
    }
}
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.cherrypine.net/#/">
                CherryPine Mods
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function Tune() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // cookies.remove('orderNum', { path: '/' });
        // cookies.remove('orderInfoList', { path: '/' });
        // history.push('/loading');
        cookies.remove('orderTune', { path: '/' });
        let dataSub = ({
            email: data.get('email'),
            fullName: data.get('firstName'),
            address1: data.get('address1'),
            address2: data.get('address2'),
            city: data.get('city'),
            state: data.get('state'),
            zip: data.get('zip'),
            country: data.get('country'),
        });
        cookies.set('orderTune', dataSub, { path: '/' });
        // console.log(dataSub);
        setActiveStep(activeStep + 1);

    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="absolute" >
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >

                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            CherryPine Mods
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <CssBaseline />
                                        <AppBar
                                            position="absolute"
                                            color="default"
                                            elevation={0}
                                            sx={{
                                                position: 'relative',
                                                borderBottom: (t) => `1px solid ${t.palette.divider}`,
                                            }}
                                        >

                                        </AppBar>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h4" align="center">
                        Checkout
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{ pt: 2, pb: 5 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                Thank you for your order.
                            </Typography>
                            <Typography variant="subtitle1">
                                Your order number is #2001539. We have emailed your order
                                confirmation, and will send you an update when your order has
                                shipped.
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            {getStepContent(activeStep)}
                            
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                        Back
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                        type="submit"
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                    {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                                </Button>
                            </Box>
                                </Box>
                        </React.Fragment>
                    )}
                </Paper>
                <Copyright />
            </Container>

                                    </Grid >
                                </Box >
                            </Grid >
                        </Grid >

        <Copyright sx={{ pt: 4 }} />
                    </Container >
                </Box >
            </Box >

        </ThemeProvider >
    );
}
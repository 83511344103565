import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import ColorShop from './TempColorDropShop';


export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <ColorShop />
        </SnackbarProvider>
    );
}
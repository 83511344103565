import React from 'react';
import ReactDOM from 'react-dom/client';
import "./Styles/App.css";
import App from './App';

import CssBaseline from '@mui/material/CssBaseline';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <CssBaseline />
        <App />
    </>
);



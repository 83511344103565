import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Cookies from 'universal-cookie';
import { GoogleSpreadsheet } from "google-spreadsheet";
import g from "./g-sheets.json";
import Order from './OrderInfo';
// const cookies = new Cookies();
let trackInfo = {};
function Copyright(props) {
  
    // const cookies = new Cookies();
    // const orderNum = cookies.get('orderNum');


    // getOrder(parseInt(props.ordnum.cartInfo), props.gotocart);
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'- Loading - '}
            {' '}
            
            {' '}
        </Typography>
    );
}
async function getOrder(orderNum, gotocart){
    console.log('getOrder');
    console.log(orderNum);
    console.log(gotocart);

    const SPREADSHEET_ID = process.env.REACT_APP_NEW_SHEET_ID;
    const SHEET_ID = 0;
    const CLIENT_EMAIL = process.env.REACT_APP_NEW_CLIENT_EMAIL;
    const PRIVATE_KEY = g.private_key;
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
        await doc.useServiceAccountAuth({
            client_email: CLIENT_EMAIL,
            private_key: PRIVATE_KEY,
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID];
        await sheet.loadCells("A1:T2500");
        let orderLocation = 0;
        for (var i = 0; i < 2500; i++) {
            if (parseInt(orderNum.cartInfo) === parseInt(sheet.getCell(i, 12).value)) {
                orderLocation = i;
                break;
            }
        }
        //   console.log(orderLocation)
        let pStatus = sheet.getCell(orderLocation, 0).value;
        var reg = new RegExp("[ ]+", "g");
        let tempHold = [];
        if (pStatus !== null) {
            tempHold = pStatus.split("-");
            pStatus = pStatus.replace(reg, "");
            pStatus = pStatus.split("-");
        }


        let pName = sheet.getCell(orderLocation, 2).value;
        let pBatch = sheet.getCell(orderLocation, 1).value;
        let pPaid = sheet.getCell(orderLocation, 3).value;
        let pAddy = sheet.getCell(orderLocation, 4).value;
        let pBody = sheet.getCell(orderLocation, 5).value;
        let pPanel = sheet.getCell(orderLocation, 6).value;
        let pPaint = sheet.getCell(orderLocation, 2).value;
        let pShip = sheet.getCell(orderLocation, 13).value;
        let pButton = sheet.getCell(orderLocation, 7).value;
        let pEmail = sheet.getCell(orderLocation, 15).value;
        let pPricePaid = sheet.getCell(orderLocation + 1, 8).value;
        let newpAddy = '';
        for (var o = 0; o < pAddy.length; o++) {
            if (o >= (pAddy.length - 20)) {
                newpAddy += pAddy[o]
            } else if (pAddy[o] === ' ') {
                newpAddy += ' ';
            } else newpAddy += '*';
        }

        if (pPaid === null) {
            pStatus = 'Awaiting Payment'
        } else if (pBatch === null) {
            pStatus = 'Waiting For Batch To Close'
        } else if (pStatus[pStatus.length - 1].toLowerCase() === 'x') {
            pStatus = "Mod Is Completed and Shipped"
        } else pStatus = tempHold[tempHold.length - 1];

        if (pShip === null) {
            pShip = "Mod has not been shipped yet"
        }
        // PAYMENT
        //   - (empty and no batch number) = WAITING FOR BATCH TO CLOSE
        //    - O = MOD IS BEING PRINTED
        //   - P = PRINTS ARE SHIPPED TO MIKEY 
        //   - M = PRINTS ARE BEING MEDIA TUMBLED
        //   - C = PRINTS ARE BEING OILED
        //   - PR = PRINTS ARE BEING PAINTED
        //   - N = CONTACTS ARE BEING MADE
        //   - A = MOD IS BEING ASSEMBLED
        //   - S = MOD IS AWAITING SHIPMENT
        //   - X = MOD IS COMPLETED AND SHIPPED

        let temp2 = ({
            printStatus: pStatus,
            name: pName,
            paid: pPaid,
            address: newpAddy,
            body: pBody,
            panel: pPanel,
            button: pButton,
            paint: pPaint,
            pricePaid: pPricePaid,
            shipNum: pShip,
            email: pEmail,
            orderNum: parseInt(orderNum.cartInfo)
        })
        // cookies.set('orderInfoList', temp2, { path: '/' });

        // saveCookies(temp2)
        trackInfo = temp2;
        console.log('is actually');
        console.log(gotocart);
        orderNum.gotocart();
    } catch (e) {
        console.error("Error: ", e);
    }
}
// function saveCookies(orderInfoList){
//     if (orderInfoList.printStatus !== 'Batch Name'){
//     let nowURL = window.location.href;
//     nowURL = nowURL.split('#/');
//     let buildURL = nowURL[0] + "#/order/" + orderInfoList.orderNum;
//     const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
//     if (newWindow) newWindow.opener = null
//     }else {
//         let nowURL = window.location.href;
//         nowURL = nowURL.split('#/');
//         let buildURL = nowURL[0] + "#/error";
//         const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
//         if (newWindow) newWindow.opener = null
//     }
// }
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const Loading2 = (cartInfo, gotocart) => {
    console.log('load2')
    console.log(cartInfo);
    console.log(gotocart);
    getOrder(cartInfo, gotocart)
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }}/>
            </Container>
        </ThemeProvider>
    );
}

export default function Loading({ cartInfo }) {
    const [state, setState] = React.useState('start')

    return (
        <>
            {state === 'start' && (
                <Loading2 cartInfo={cartInfo} gotocart={() => setState('cart')} />
            )}
            {state === 'cart' && <Order cartInfo={trackInfo} />}
        </>
    );
}
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function MaingPage() {

    return (
        <ThemeProvider theme={defaultTheme}>
            
            <Grid container component="main" sx={{ height: '100vh'}}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://i.imgur.com/TWpDekZ.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ 
                    backgroundImage: 'url(https://i.imgur.com/e8OCJKM.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                     }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <div role="presentation" >
                            <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: '50px', color: 'white'}}>
                                {/* <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="mailto:support@cherrypine.net"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    Help
                                </Link>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/#/track"
                                >
                                    <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    Track
                                </Link>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/#/shop"
                                >
                                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    Shop
                                </Link>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/#/tune"
                                >
                                    <HomeRepairServiceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    Tune Up
                                </Link> */}
                            </Breadcrumbs>
                        </div>
                        <Link variant="outlined" sx={{ color: 'white', marginTop: '50px', fontSize: '24px', position: 'absolute', top: '0px' }} href="https://www.facebook.com/groups/cherrypine">Learn More</Link>
                        <Link variant="outlined" sx={{ color: 'white', marginTop: '50px', fontSize: '24px', position: 'absolute', top: '50px' }} href="https://www.cherrypine.net/#/privacy">Privacy Policy</Link>
                        <Link variant="outlined" sx={{ color: 'white', marginTop: '50px', fontSize: '24px', position: 'absolute', top: '100px' }} href="https://www.cherrypine.net/#/terms">Terms & Conditions</Link>
                        <Link variant="outlined" sx={{ color: 'white', marginTop: '50px', fontSize: '24px', position: 'absolute', top: '150px' }} href="https://www.cherrypine.net/#/fulfilment">Fulfillment Policy</Link>
                    </Box>
                </Grid>
            </Grid>
            
        </ThemeProvider>
    );
}
import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();
function createData(name, orderInfo) {
    // const orderInfo = cookies.get('orderInfoList');
    if (orderInfo !== undefined) {
        if (name === 'name') {
            return orderInfo.name
        } else if (name === 'address') {
            return orderInfo.address
        } else if (name === 'shipNum') {
            return orderInfo.shipNum
        } else if (name === 'orderNum') {
            return orderInfo.orderNum
        }

    } else {
        return ('');
    }

}

export default function OrderPerson({ orderInfo }) {
    return (
        <React.Fragment>
            <Title>Order #{createData('orderNum', orderInfo)}</Title>
            <Typography component="p" variant="h4">
                {createData('name', orderInfo)}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
                Address: {createData('address', orderInfo)}
            </Typography>
            <div>
                <Link color="primary" href={"https://tools.usps.com/go/TrackConfirmAction?tLabels=" + createData('shipNum')} >
                    Shipping Number: {createData('shipNum', orderInfo)}
                </Link>
            </div>
        </React.Fragment>
    );
}
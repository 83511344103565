import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import OrderStatus from './OrderStatus';
import OrderItems from './OrderItems';
// import Cookies from 'universal-cookie';
import OrderPerson from './OrderPerson';
const drawerWidth = 240;
// const cookies = new Cookies();
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

// function CheckCookie(props) {
//     const orderInfo = cookies.get('orderInfoList');
//     let nowURL = window.location.href;
//     nowURL = nowURL.split('#/order/');
//     if(orderInfo === undefined){
//         if(nowURL[nowURL.length - 1] === '' && nowURL.length > 1){
//             let url = nowURL[0] + '#/track';
//             // let url = "https://cherrypine.net/#/order/" + orderInfoList.orderNum;
//             const newWindow = window.open(url, "_self", 'noopener,noreferrer');
//             if (newWindow) newWindow.opener = null
//         }else {
//             let buildURL = nowURL[0] + '#/loading';
//             cookies.set('orderNum', parseInt(nowURL[nowURL.length - 1]), { path: '/' });
//             const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
//             if (newWindow) newWindow.opener = null
//         }
//     }
//     return <></>
// }
// TODO remove, this demo shouldn't need to reset the theme.
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
export default function Order({cartInfo}) {
    const [open] = React.useState(true);
    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ display: 'flex'}}>
                <CssBaseline />
                <AppBar position="fixed" open={open} sx={{ width: '100%', top: 0 }}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            CherryPine Mods
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <OrderPerson orderInfo={cartInfo}/>
                                </Paper>
                            </Grid>
                            {/* Recent Deposits */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <OrderStatus orderInfo={cartInfo} />
                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
                                    <OrderItems orderInfo={cartInfo} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
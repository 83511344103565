import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();
// Generate Order Data
function createData(name, orderInfo) {
    // const orderInfo = cookies.get('orderInfoList');
    if(orderInfo !== undefined){
        if(name === 'body'){
            return orderInfo.body
        }else if(name === 'panel'){
            return orderInfo.panel
        } else if (name === 'button') {
            return orderInfo.button
        }

    }else {
        return ('');
    }

}


export default function OrderItems({ orderInfo }) {
    return (
        <React.Fragment>
            <Title>Order Info</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Body</TableCell>
                        <TableCell>Panel</TableCell>
                        <TableCell>Button</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        <TableRow key={0}>
                        <TableCell>{createData('body', orderInfo)}</TableCell>
                        <TableCell>{createData('panel', orderInfo)}</TableCell>
                        <TableCell>{createData('button', orderInfo)}</TableCell>
                        </TableRow>
                    <TableRow key={1}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={2}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        </TableRow>
                    <TableRow key={3}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        </TableRow>
                    <TableRow key={4}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={5}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={6}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={7}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={8}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={9}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={10}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow key={11}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                        
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
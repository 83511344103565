import * as React from 'react';
import { styled, createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Cookies from 'universal-cookie';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useSnackbar } from 'notistack';
import shopDataTemp from './tempProducts.json';
import CartNoti from "./CartNoti";
import Modal from '@mui/material/Modal';
const cookies = new Cookies();
let itemData = shopDataTemp;
let endCart = [];

// const itemData = [
//     {
//         img: 'https://i.imgur.com/PyWLxhG.png',
//         name: 'Sequoia-S',
//         description: 'Mech Boro',
//         id: 0,
//         price: 175,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://i.imgur.com/2fy4R8c.png',
//         name: 'Bonsai-S',
//         description: 'Mech Boro',
//         id: 1,
//         price: 175,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeRFBDBekB952Ftav2TF5o9'
//     },
//     {
//         img: 'https://i.imgur.com/7dpQEX3.png',
//         name: 'Oak-S',
//         description: 'Mech Boro',
//         id: 2,
//         price: 175,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeRFkDBekB952Ft2nxmmn0Y'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//         name: 'Coffee',
//         description: '@nolanissac',
//         id: 3,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//         name: 'Hats',
//         description: '@hjrc33',
//         id: 4,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//         name: 'Honey',
//         description: '@arwinneil',
//         id: 5,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//         name: 'Basketball',
//         description: '@tjdragotta',
//         id: 6,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//         name: 'Fern',
//         description: '@katie_wasserman',
//         id: 7,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//         name: 'Mushrooms',
//         description: '@silverdalex',
//         id: 8,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//         name: 'Tomato basil',
//         description: '@shelleypauls',
//         id: 9,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//         name: 'Sea star',
//         description: '@peterlaster',
//         id: 10,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//         name: 'Bike',
//         description: '@southside_customs',
//         id: 11,
//         price: 0,
//         category: 'mod',
//         inventory: 1,
//         stripeId: 'price_1MeREeDBekB952FtaLGfM5bg'
//     },
// ];
let cartNow = cookies.get('cartItems');
if (cartNow === undefined) {
    cartNow = [];
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.cherrypine.net/#/">
                CherryPine Mods
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 175;
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const cartSize = () => {
    console.log('here 3')

    return endCart.length;
    //for when we use cookies
    // cartNow = cookies.get('cartItems');
    // if (cartNow !== undefined) {
    //     return cartNow.length;
    // } else return 0;
}
// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         '& .MuiDrawer-paper': {
//             position: 'relative',
//             whiteSpace: 'nowrap',
//             width: drawerWidth,
//             transition: theme.transitions.create('width', {
//                 easing: theme.transitions.easing.sharp,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             boxSizing: 'border-box',
//             ...(!open && {
//                 overflowX: 'hidden',
//                 transition: theme.transitions.create('width', {
//                     easing: theme.transitions.easing.sharp,
//                     duration: theme.transitions.duration.leavingScreen,
//                 }),
//                 width: theme.spacing(3),
//                 [theme.breakpoints.up('sm')]: {
//                     width: theme.spacing(5),
//                 },
//             }),
//         },
//     }),
// );

// TODO remove, this demo shouldn't need to reset the theme.
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

let defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
defaultTheme = responsiveFontSizes(defaultTheme);
const ShoppingPage = (goToCart ) => {
    // const [state, setState] = React.useState({
    //     open: false,
    //     vertical: 'top',
    //     horizontal: 'center',
    // });
    const [itemLists] = React.useState(itemData);
    const [open, setOpen] = React.useState(false);
    const [cimg, setCimg] = React.useState("https://i.imgur.com/4MwrbGt.png");
    const [ctitle, setCtitle] = React.useState("Sapling - Feek [Fake Peek] (Cerakote)");
    const [cDescr, setCdescr] = React.useState("18650 Mech - Cerakoted Feek");
    const [cid, setCid] = React.useState("24");

    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (variant) => (e) => {
        // variant could be success, error, warning, info, or default
        let name = "Item";
        let stock = 0;
        console.log('here 1')
        console.log(variant)
        for (var i = 0; i < itemData.length; i++) {
            if (parseInt(e.target.id) === parseInt(itemData[i].id)) {
                name = itemData[i].name;
                stock = itemData[i].inventory;
                break;
            }
        }
        if (parseInt(stock) === 0 && stock !== "x") {
            variant = "error"
            enqueueSnackbar(`${name} Out of Stock!`, { variant });
        } else {
            enqueueSnackbar(`${name} added to your cart!`, { variant });
            addToCart(e);
        }
        // if (variant === 'success') {
        //     enqueueSnackbar(`${name} added to your cart!`, { variant });
        //     addToCart(e);
        // } else {
        //     enqueueSnackbar(`${name} Out of Stock!`, { variant });
        // }

    };

    const handleOpen = (variant) => () => {
        console.log('here 2')
        for (var xx = 0; xx < itemData.length; xx++){
            if(variant === itemData[xx].id){
                setCimg(itemData[xx].img);
                setCtitle(itemData[xx].name + " $" + itemData[xx].price + ".00");
                setCdescr(itemData[xx].description);
                setCid(itemData[xx].id);
            }
        }
        setOpen(true);
    };
    const handleClose = (variant) => () => {
        setOpen(false);
    };

    // const handleStock = () => {
    //     // itemData = '';

    //     cookies.remove('allShopData', { path: '/' });
    //     let nowURL = window.location.href;
    //     nowURL = nowURL.split('#/');
    //     let buildURL = nowURL[0] + "#/shop";
    //     const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
    //     if (newWindow) newWindow.opener = null
    // }
    console.log('here 5')

    let initSize = endCart.length;
    //for cookies
    // if (cartNow !== undefined) {
    //     initSize = endCart.length;
    // }
    const [cartSizeX, setCartSizeX] = React.useState(initSize);
    

    // const checkout = () => {
    //     // cookies.remove('cartItems', { path: '/' });
    //     // console.log('cookies cleared')
    //     goToCart.goToCart();
    //     // let nowURL = window.location.href;
    //     // nowURL = nowURL.split('#/');
    //     // let buildURL = nowURL[0] + "#/cart";
    //     // const newWindow = window.open(buildURL, "_self", 'noopener,noreferrer');
    //     // if (newWindow) newWindow.opener = null
    // };
    const addToCart = (e) => {

        // cartNow = cookies.get('cartItems');
        // console.log(cartNow);

        // let cartInfo = [];
        let currentItem = {};
        let itemAdded = false;
        let newCart = false;
        console.log('here 6')

        for (var i = 0; i < itemData.length; i++) {
            if (parseInt(e.target.id) === parseInt(itemData[i].id)) {
                currentItem = itemData[i];
                break;
            }
        }
        console.log(currentItem);
        if (parseInt(currentItem.inventory) === 0 && currentItem.inventory !== "x"){
            console.log('this is running')
            handleClickVariant("error");
        } else handleClickVariant(currentItem.variant);

        if (endCart !== undefined) {
            // cartInfo = cartNow;
            console.log('here 7')

            for (var x = 0; x < endCart.length; x++) {
                console.log('x: ', x)
                if (parseInt(currentItem.id) === parseInt(endCart[x].id)) {
                    itemAdded = true;
                    endCart[x].quanitity += 1;
                    break;
                }
            }
        } else {
            newCart = true;
            endCart = [{
                img: currentItem.img,
                name: currentItem.name,
                description: currentItem.description,
                id: currentItem.id,
                price: currentItem.price,
                category: currentItem.category,
                inventory: currentItem.inventory,
                stripeId: currentItem.stripeId,
                quanitity: 1
            }];
        }
        if (!itemAdded && !newCart) {
            endCart.push({
                img: currentItem.img,
                name: currentItem.name,
                description: currentItem.description,
                id: currentItem.id,
                price: currentItem.price,
                category: currentItem.category,
                inventory: currentItem.inventory,
                stripeId: currentItem.stripeId,
                quanitity: 1
            })
        }
        // endCart = cartInfo;
        // cookies.set('cartItems', cartInfo, { path: '/' });
        e.preventDefault();
    };
    // React.useEffect(() => {
    //     let tempCookies = cookies.get('allShopData');
    //     console.log('here 8')

    //     for (var i = 0; i < itemData.length; i++) {
    //         console.log('here 1')

    //         for (var x = 0; x < tempCookies.length; x++) {
    //             if (itemData[i].id === tempCookies[x].id) {
    //                 if (itemData[i].inventory !== tempCookies[x].inventory) {
    //                     window.location.reload(false);
    //                 }
    //             }
    //         }
    //     }
    // }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="absolute" >
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >

                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            CherryPine Mods
                        </Typography>
                        <IconButton color="inherit">
                            <Badge badgeContent={cartSizeX} color="secondary" onClick={goToCart.goToCart}>
                                Check Out ( <ShoppingCartIcon /> )
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        position: 'relative',
                                        backgroundColor: 'grey.800',
                                        color: '#fff',
                                        mb: 4,
                                        height: '351px',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundImage: `url(https://i.imgur.com/TWpDekZ.jpg)`,
                                    }}
                                >
                                    {/* Increase the priority of the hero background image */}
                                    {<img style={{ display: 'none' }} src={'https://i.imgur.com/TWpDekZ.jpg'} alt={'https://i.imgur.com/TWpDekZ.jpg'} />}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            left: 0,
                                            backgroundColor: 'rgba(0,0,0,.3)',
                                        }}
                                    />
                                    <Grid container>
                                        <Grid item md={6}>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    p: { xs: 3, md: 6 },
                                                    pr: { md: 0 },
                                                }}
                                            >
                                                <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                                                    {'TIPS AVAILABLE NOW'}
                                                </Typography>
                                                <Typography variant="h5" color="inherit" paragraph>
                                                    {'STOCK UP '}
                                                </Typography>
                                                <Link variant="subtitle1" href="https://www.facebook.com/groups/cherrypine" target="_blank" rel="noopener noreferrer">
                                                    {'More info in the group'}
                                                </Link>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    SCROLL DOWN
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    {/* <Button variant="contained" onClick={() => {
                                        handleStock();
                                    }}>
                                        Refresh
                                    </Button> */}
                                </Paper>
                            </Grid>
                            {/* Recent Orders */}
                            <Modal
                                open={open}
                                onClose={handleClose(1)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            component="img"
                                            alt="green iguana"
                                            height="140"
                                            image={cimg}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {ctitle}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {cDescr}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" onClick={handleClose(1)}>Close</Button>
                                            <Button size="small" id={cid} onClick={handleClickVariant("success")} >Add To Cart</Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Modal>
                            <Grid item xs={12}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                        {itemLists.map((item) => (
                                            <Grid item xs={2} sm={4} md={4} key={item.img} id={item.id}>
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.name}
                                                        loading="lazy"
                                                        id={item.id}
                                                        onClick={handleOpen(item.id)}
                                                    />
                                                  
                                                    <ImageListItemBar
                                                        title={item.name}
                                                        subtitle={"$" + item.price + ".00 - " + item.description}
                                                        id={item.id}
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setCartSizeX(cartSize);
                                                        }}
                                                        actionIcon={

                                                            <img
                                                                src={item.iconId}
                                                                srcSet={item.iconId}
                                                                alt={item.name}
                                                                onClick={handleClickVariant(item.variant)}

                                                                loading="lazy"
                                                                id={item.id}
                                                                height={'40px'}
                                                                sx={{ paddingRight: '10px', cursor: 'pointer' }}
                                                            />

                                                        }

                                                    />
                                                </ImageListItem>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <Copyright sx={{ pt: 4 }} />
                        <Copyright sx={{ pt: 4 }} />
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>

        </ThemeProvider>
    );
}


export default function ColorShop() {
    const [state, setState] = React.useState('start')

    return (
        <>
        {state === 'start' && (
                <ShoppingPage goToCart={() => setState('cart')} />
        )}
            {state === 'cart' && <CartNoti cartInfo={endCart}/>}
        </>
    );
}

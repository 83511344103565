import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import CartPage from './CartPage';


export default function CartNoti({ cartInfo }) {
    return (
        <SnackbarProvider maxSnack={3}>
            <CartPage cartInfo={cartInfo}/>
        </SnackbarProvider>
    );
}
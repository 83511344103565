import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import mapleList from './mapleProducts.json';
import CartNoti from "./CartNoti";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled, createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import Plain21700 from './maplepics/21700plain.png';
import Aztec from './maplepics/aztec.png';
import Black from './maplepics/black.png';
import Blue from './maplepics/blue.png';
import Camo from './maplepics/camo.png';
import CrocGreen from './maplepics/crocgreen.png';
import DesertTan from './maplepics/deserttan.png';
import Feek from './maplepics/feek.png';
import Flow from './maplepics/flow.png';
import Galaxy from './maplepics/galaxy.png';
import Green from './maplepics/green.png';
import Grey from './maplepics/grey.png';
import Koi from './maplepics/koi.png';
import Orange from './maplepics/orange.png';
import Pink from './maplepics/pink.png';
import PlainAngular from './maplepics/plain-angular.png';
import PlainRound from './maplepics/plain-round.png';
import Purpl from './maplepics/purpl.png';
import Red from './maplepics/red.png';
import Topo from './maplepics/topo.png';
import White from './maplepics/white.png';
import Yellow from './maplepics/yellow.png';
import Silver from './maplepics/silver.png';
import MuiAppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
const steps = ['Pattern', 'Color', 'Contacts', 'Quantity'];
let patternChoice = "";
let colorChoice = "";
let contactChoice = "";
let finalChoices = [];
let choseNonPlain = false;
function Copyright(props) {

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.cherrypine.net/#/">
                CherryPine Mods
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const drawerWidth = 175;
// TODO remove, this demo shouldn't need to reset the theme.
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
function getStepContent(step) {
    switch (step) {
        case 0:
            return <MaplePattern />;
        case 1:
            return <MapleColor />;
        case 2:
            return <MapleContacts />;
        case 3:
            return <MapleQuantity />;
        default:
            throw new Error('Unknown step');
    }
}


let defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
defaultTheme = responsiveFontSizes(defaultTheme);

export default function MapleBuild() {
    const [activeStep, setActiveStep] = React.useState(0);

    
    const handleSubmit = (event) => {
        event.preventDefault();
        setActiveStep(activeStep + 1);
    };
    const handleBack = (event) => {
        setActiveStep(activeStep - 1);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="absolute" >
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >

                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            CherryPine Mods
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.3)',
                    }}
                />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{
                        mt: 4, mb: 4
                    }} >
                        <Grid item xs={12} >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                    alignItems="center"
                                    justifyContent="center" container>
                                    <Stepper activeStep={activeStep} >
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel >{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Grid
                                        alignItems="center"
                                        justifyContent="center" container spacing={3}>
                                        {/* Chart */}
                                        <Grid item >
                                            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                                                <Grid item xs={12}>
                                                    <Box sx={{ flexGrow: 1 }}>


                                                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>

                                                            {activeStep === steps.length ? (
                                                                <React.Fragment>
                                                                    <Typography variant="h5" gutterBottom>
                                                                        <CartNoti cartInfo={finalChoices} />
                                                                    </Typography>
                                                                    <Typography variant="subtitle1">

                                                                    </Typography>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                                                        {getStepContent(activeStep)}
                                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            {activeStep !== 0 && (
                                                                                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                                                                    Back
                                                                                </Button>
                                                                            )}

                                                                            <Button
                                                                                variant="contained"
                                                                                type="submit"
                                                                                sx={{ mt: 3, ml: 1 }}
                                                                            >
                                                                                {activeStep === steps.length - 1 ? 'Checkout' : ' '}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </React.Fragment>
                                                            )}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Copyright sx={{ pt: 4 }} />
                                    <Copyright sx={{ pt: 4 }} />
                                    <Copyright sx={{ pt: 4 }} />
                                </Grid>
                            </Box>
                        </Grid>
                    </Container >
                </Box >
            </Box >

        </ThemeProvider >
    );
}

function MaplePattern() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleSubmit = (event) => {
        if (event.value > 2) {
            choseNonPlain = true;
        }
        if (choseNonPlain && isSpecial) {
            colorDataNoSpecial.shift();
        }
        patternChoice = event;
    };
    return (
        <React.Fragment>
            <ImageList sx={{ width: 350, height: 450, overflowX: 'hidden' }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div">Choose A Pattern <a href="https://imgur.com/a/13ehNag" target="_blank" rel="noreferrer">See Larger Pictures</a></ListSubheader>
                </ImageListItem>
                {patternData.map((item) => (
                    <ImageListItem key={item.img}>

                        <Button
                            type="submit"
                            value={item.value}
                            // onSubmit={handleSubmit}
                            onClick={() => {
                                setActiveStep(activeStep + 1);
                                handleSubmit(item)
                            }}
                            sx={{ mt: 0, ml: 0, overflowX: 'hidden' }}
                        >
                            <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.author}
                                    loading="lazy"
                                    id={item.title}
                                />
                            </ImageListItem>


                            <ImageListItemBar
                                sx={{
                                    background:
                                        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                    "& .MuiImageListItemBar-title": { fontSize: ".65rem" }, //styles for title
                                    "& .MuiImageListItemBar-subtitle": { fontSize: ".7rem" }, //styles for subtitle
                                }}
                                title={item.title}
                                subtitle={item.author}

                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.title}`}
                                    >

                                    </IconButton>
                                }
                            />

                        </Button>
                    </ImageListItem>
                ))}
            </ImageList>
        </React.Fragment>
    );
}
const patternData = [
    {
        img: Plain21700,
        title: 'Plain (21700)',
        author: 'base - $155',
        id: '135',
        rows: 2,
        cols: 2,
        featured: true,
        value: 0
    },
    {
        img: PlainAngular,
        title: 'Plain Angular (18650)',
        author: 'base - $145',
        id: '132',
        value: 1
    },
    {
        img: PlainRound,
        title: 'Plain Round (18650)',
        author: 'base - $145',
        id: '132',
        value: 2
    },
    {
        img: Koi,
        title: 'Koi (18650)',
        author: 'base - $145',
        id: '132',
        cols: 2,
        value: 3
    },
    {
        img: Aztec,
        title: 'Aztec (18650)',
        author: 'base - $145',
        id: '132',
        cols: 2,
        value: 4
    },
    {
        img: Flow,
        title: 'Flow (18650)',
        author: 'base - $145',
        id: '132',
        rows: 2,
        cols: 2,
        value: 5
    },
    {
        img: Topo,
        title: 'Topo (18650)',
        author: 'base - $145',
        id: '132',
        value: 6
    }
];
const dumbydata = [
    {
        img: "https://i.imgur.com/AJiXieJ.png",
        title: '1',
        author: ' ',
        rows: 2,
        cols: 2,
        featured: true,
        value: 1
    },
    {
        img: "https://i.imgur.com/AJiXieJ.png",
        title: '2',
        author: ' ',
        value: 2
    },
    {
        img: "https://i.imgur.com/AJiXieJ.png",
        title: '3',
        author: ' ',
        value: 3
    },
    {
        img: "https://i.imgur.com/AJiXieJ.png",
        title: '4',
        author: ' ',
        cols: 2,
        value: 4
    },
    {
        img: "https://i.imgur.com/AJiXieJ.png",
        title: '5',
        author: ' ',
        cols: 2,
        value: 5
    },
    {
        img: "https://i.imgur.com/AJiXieJ.png",
        title: '6',
        author: ' ',
        rows: 2,
        cols: 2,
        featured: true,
        value: 6
    }
];
let colorDataNoSpecial = [
    {
        img: Grey,
        title: 'Grey',
        author: '+ $00.00',
        id: '0',
        rows: 2,
        cols: 2,
        featured: true,
        value: 0
    },
    {
        img: Black,
        title: 'Black',
        author: '+ $00.00',
        id: '0',
        value: 1
    },
    {
        img: White,
        title: 'White',
        author: '+ $45.00',
        id: '134',
        value: 2
    },
    {
        img: Orange,
        title: 'Orange',
        author: '+ $45.00',
        id: '134',
        cols: 2,
        value: 3
    },
    {
        img: Green,
        title: 'Green',
        author: '+ $45.00',
        id: '134',
        cols: 2,
        value: 4
    },
    {
        img: Red,
        title: 'Red',
        author: '+ $45.00',
        id: '134',
        rows: 2,
        cols: 2,
        featured: true,
        value: 5
    },
    {
        img: Blue,
        title: 'Blue',
        author: '+ $45.00',
        id: '134',
        value: 6
    },
    {
        img: Purpl,
        title: 'Purple-ish',
        author: '+ $45.00',
        id: '134',
        value: 7
    },
    {
        img: Pink,
        title: 'Pink',
        author: '+ $45.00',
        id: '134',
        rows: 2,
        cols: 2,
        value: 8
    },
    {
        img: Feek,
        title: 'Feek',
        author: '+ $45.00',
        id: '134',
        value: 9
    },
    {
        img: CrocGreen,
        title: 'Croc Green',
        author: '+ $45.00',
        id: '134',
        value: 10
    },
    {
        img: Yellow,
        title: 'Yellow',
        author: '+ $45.00',
        id: '134',
        cols: 2,
        value: 11
    },
    {
        img: DesertTan,
        title: 'Desert Tan',
        author: '+ $45.00',
        id: '134',
        cols: 2,
        value: 12
    },
];
const colorDataCamo = {
    img: Camo,
    title: 'CAMO - Limited',
    author: '+ $85.00',
    id: '136',
    cols: 2,
    value: 13
};

const colorDataGalaxy = {
    img: Galaxy,
    title: 'GALAXY - Limited',
    author: '+ $85.00',
    id: '136',
    cols: 2,
    value: 13
};

// var currentDate = new Date().toJSON().slice(0, 10);
// var from = new Date('2024/02/27');
// var to = new Date('2024/03/02');

// var from2 = new Date('2024/03/13');
// var to2 = new Date('2024/03/16');
// var check = new Date(currentDate);

let isSpecial = false;
const url = window.location.href;
let runSpecial = 0;
if (url === "http://localhost:3000/#/maple123" || url === "https://www.cherrypine.net/#/maple123") {
    runSpecial = prompt("enter 1 for camo - 2 for galaxy");
}

if (runSpecial === "1") {
    isSpecial = true;
    colorDataNoSpecial.unshift(colorDataCamo);
} else if (runSpecial === "2") {
    isSpecial = true;
    colorDataNoSpecial.unshift(colorDataGalaxy);
}
const contactData = [
    {
        img: "https://i.imgur.com/aJdsUvq.png",
        title: 'Copper',
        author: '+ $00.00',
        id: '0',
        rows: 2,
        cols: 2,
        featured: true,
        value: 0
    },
    {
        img: Silver,
        title: 'Silver',
        author: '+ $35.00',
        id: '133',
        value: 1
    }
];
function MapleColor() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleSubmit = (event) => {
        colorChoice = event;
    };

    return (
        <React.Fragment>
            <ImageList sx={{ width: 350, height: 450, overflowX: 'hidden' }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div">Choose A Color</ListSubheader>
                </ImageListItem>
                {colorDataNoSpecial.map((item) => (
                    <ImageListItem key={item.img}>

                        <Button
                            type="submit"
                            value={item.value}
                            // onSubmit={handleSubmit}
                            onClick={() => {
                                setActiveStep(activeStep + 1);
                                handleSubmit(item)
                            }}
                            sx={{ mt: 0, ml: 0, overflowX: 'hidden' }}
                        >
                            <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.author}
                                    loading="lazy"
                                    id={item.title}
                                />
                            </ImageListItem>


                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.author}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.title}`}
                                    >

                                    </IconButton>
                                }
                            />

                        </Button>
                    </ImageListItem>
                ))}
            </ImageList>
        </React.Fragment>
    );
}
function MapleContacts() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleSubmit = (event) => {
        contactChoice = event;
    };
    return (
        <React.Fragment>
            <ImageList sx={{ width: 350, height: 450, overflowX: 'hidden' }}>
                <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div">Choose Your Contacts</ListSubheader>
                </ImageListItem>
                {contactData.map((item) => (
                    <ImageListItem key={item.img}>

                        <Button
                            type="submit"
                            value={item.value}
                            // onSubmit={handleSubmit}
                            onClick={() => {
                                setActiveStep(activeStep + 1);
                                handleSubmit(item)
                            }}
                            sx={{ mt: 0, ml: 0, overflowX: 'hidden' }}
                        >
                            <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.author}
                                    loading="lazy"
                                    id={item.title}
                                />
                            </ImageListItem>


                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.author}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.title}`}
                                    >

                                    </IconButton>
                                }
                            />

                        </Button>
                    </ImageListItem>
                ))}
            </ImageList>
        </React.Fragment>
    );
}
function MapleQuantity() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleSubmit = (event) => {

        for (var x = 0; x < mapleList.length; x++) {
            if (patternChoice.id === mapleList[x].id) {
                finalChoices.push({
                    img: mapleList[x].img,
                    name: mapleList[x].name,
                    description: mapleList[x].description,
                    id: mapleList[x].id,
                    price: mapleList[x].price,
                    category: mapleList[x].category,
                    inventory: mapleList[x].inventory,
                    stripeId: mapleList[x].stripeId,
                    quanitity: event
                })
                break;
            }
        }
        let boughtColor = false;
        if (colorChoice.value >= 2) {
            boughtColor = true;
            for (var o = 0; o < mapleList.length; o++) {
                if (colorChoice.id === mapleList[o].id) {
                    finalChoices.push({
                        img: mapleList[o].img,
                        name: mapleList[o].name,
                        description: mapleList[o].description,
                        id: mapleList[o].id,
                        price: mapleList[o].price,
                        category: mapleList[o].category,
                        inventory: mapleList[o].inventory,
                        stripeId: mapleList[o].stripeId,
                        quanitity: event
                    })
                    break;
                }
            }
        }
        let boughtSilver = false;
        if (contactChoice.value === 1) {
            boughtSilver = true;
            finalChoices.push({
                img: mapleList[2].img,
                name: mapleList[2].name,
                description: mapleList[2].description,
                id: mapleList[2].id,
                price: mapleList[2].price,
                category: mapleList[2].category,
                inventory: mapleList[2].inventory,
                stripeId: mapleList[2].stripeId,
                quanitity: event
            })
        }
        console.log("HERE")
        console.log("HERE")
        console.log(finalChoices)
        console.log("HERE")
        console.log("HERE")
        if (boughtColor) {
            if (colorChoice.value !== 13) {
                if (colorDataNoSpecial[0].value === 13) {
                    finalChoices[0].name += " - " + patternData[patternChoice.value].title + " - Cerakoted " + colorDataNoSpecial[colorChoice.value + 1].title;
                } else {
                    finalChoices[0].name += " - " + patternData[patternChoice.value].title + " - Cerakoted " + colorDataNoSpecial[colorChoice.value].title;
                }

                if (boughtColor) {
                    if (colorDataNoSpecial[0].value === 13) {
                        finalChoices[1].name = colorDataNoSpecial[colorChoice.value + 1].title;
                    } else {
                        finalChoices[1].name = colorDataNoSpecial[colorChoice.value].title;
                    }

                    finalChoices[0].category += " Cerakoted - " + colorDataNoSpecial[colorChoice.value].title;
                    finalChoices[1].img = "cerakoted";
                };
            } else {
                finalChoices[0].name += " - " + patternData[patternChoice.value].title + " - Cerakoted " + colorDataNoSpecial[0].title;
                if (boughtColor) {
                    finalChoices[1].name = colorDataNoSpecial[0].title;
                    finalChoices[0].category += " Cerakoted - " + colorDataNoSpecial[0].title;
                    finalChoices[1].img = "cerakoted2";
                };
            }
        }else {
            console.log("COLOR")
            console.log(patternChoice)
            finalChoices[0].name += " - " + patternData[patternChoice.value].title + " - " + colorDataNoSpecial[colorChoice.value].title;
        }

        if (boughtSilver) {
            finalChoices[1].inventory = "silver";
        }
        console.log("HERE")
        console.log("HERE")
        console.log(finalChoices)
        console.log("HERE")
        console.log("HERE")
    };
    return (
        <React.Fragment>
            {activeStep > steps.length ? (
                <React.Fragment>
                    <CartNoti cartInfo={finalChoices} />
                </React.Fragment>
            ) : (
                <ImageList sx={{ width: 350, height: 450, overflowX: 'hidden' }}>
                    <ImageListItem key="Subheader" cols={2}>
                        <ListSubheader component="div">Choose Quanitity</ListSubheader>
                    </ImageListItem>
                    {dumbydata.map((item) => (
                        <ImageListItem key={item.img}>

                            <Button
                                type="submit"
                                value={item.value}
                                // onSubmit={handleSubmit}
                                onClick={() => {
                                    setActiveStep(activeStep + 1);
                                    handleSubmit(item.value)
                                }}
                                sx={{ mt: 0, ml: 0, overflowX: 'hidden' }}
                            >
                                <ImageListItem key={item.img}>
                                    <img
                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.author}
                                        loading="lazy"
                                        id={item.title}
                                    />
                                </ImageListItem>


                                <ImageListItemBar
                                    title={item.title}
                                    subtitle={item.author}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                        >

                                        </IconButton>
                                    }
                                />

                            </Button>
                        </ImageListItem>
                    ))}
                </ImageList>
            )}

        </React.Fragment>
    );
}

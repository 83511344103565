import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Cookies from 'universal-cookie'; 
// import { useHistory } from "react-router-dom";
import Loading from './Loading';
// const cookies = new Cookies();
// TODO remove, this demo shouldn't need to reset the theme.
let ordNum = 0;
const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const Track2 = (goToCart) => {
    // let history = useHistory();
    // cookies.remove('orderNum', { path: '/' });
    // cookies.remove('orderInfoList', { path: '/' });

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        ordNum = data.get('orderNum');
        console.log('supposed to be')
        console.log(goToCart)

        goToCart.goToCart();
        // cookies.remove('orderNum', { path: '/' });
        // cookies.remove('orderInfoList', { path: '/' });
        // cookies.set('orderNum', data.get('orderNum'), { path: '/' });
        // history.push('/loading')
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://i.imgur.com/TWpDekZ.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Track Your Order
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Order Number"
                                name="orderNum"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default function Track() {
    const [state, setState] = React.useState('start')

    return (
        <>
            {state === 'start' && (
                <Track2 goToCart={() => setState('cart')} />
            )}
            {state === 'cart' && <Loading cartInfo={ordNum} />}
        </>
    );
}
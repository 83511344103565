import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Paper from '@mui/material/Paper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import HelpIcon from '@mui/icons-material/Help';
import ClearIcon from '@mui/icons-material/Clear';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
export default function FixedBottomNavigation() {
    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);

    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
    }, [value]);
    const clearcook = () => {
        cookies.remove('cartItems', { path: '/' });
        window.location.reload(false);
    }

    return (

        <ThemeProvider theme={defaultTheme}>
        <Box sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: '60px' }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Home" icon={<HomeIcon/>} href="/#/" />
                    {/* <BottomNavigationAction label="Help" icon={<HelpIcon/> } href="mailto:support@cherrypine.net"/> */}
                    <BottomNavigationAction label="Tune Up" icon={<HomeRepairServiceIcon/>} href="/#/tune" />
                    <BottomNavigationAction label="Track" icon={<WhatshotIcon/>} href="/#/track"  />
                        <BottomNavigationAction label="Shop" icon={<GrainIcon />} href="/#/shop" />
                    <BottomNavigationAction label="Clear Cart" icon={<ClearIcon />} onClick={clearcook} />
                </BottomNavigation>
            </Paper>
        </Box>
        </ThemeProvider>
    );
}
